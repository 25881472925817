<template>
  <div class="reports-pagination">
    <el-pagination
      background
      :current-page.sync="currentPage2"
      :page-sizes="[10, 20, 30, 40, 60]"
      :page-size="$store.state.reportsStore.queryParams.perPage"
      layout="sizes, prev, pager, next, total"
      :total="$store.getters.filteredReport4.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  data() {
    return {
      currentPage2: 1
    }
  },
  methods: {
    ...mapMutations(['SET_QUERY_PARAM']),
    handleSizeChange(size) {
      this.SET_QUERY_PARAM({ name: 'perPage', value: size })
    },
    handleCurrentChange(current) {
      this.SET_QUERY_PARAM({ name: 'currentPage', value: current })
    }
  }
}
</script>

<style>
.reports-pagination {
  background-color: white;
  display: flex;
  justify-items: flex-start;
  align-items: center;
  height: 100%;
}
</style>
