<template>
  <div class="reports-toolbar">
    <toExcel />
    <el-button
      style="
        min-width: 5rem;
        height: 2.5rem;
        background-color: #ffa800;
        color: white;
      "
      icon="el-icon-search"
      @click="FILTER_DIALOG_TOGGLE(true)"
      >Filter</el-button
    >
    <FilterDialog />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import FilterDialog from './filters'
import toExcel from './toExcel'
export default {
  components: {
    FilterDialog,
    toExcel
  },
  beforeDestroy() {
    this.SET_FILTER({
      filter: {
        marka: '',
        get_truck_number: '',
        insurance: {
          start_date: '',
          end_date: ''
        },
        technical_monitoring: {
          start_date: '',
          end_date: ''
        },
        advert_permit: {
          start_date: '',
          end_date: ''
        },
        truck_oil_state: {
          start_usage: '', // num
          end_usage: '' // num
        },
        truck_tire_state: {
          start_usage: '', // num
          end_usage: '' // num
        },
        truck_accumulator_state: {
          start_usage: '', // num
          end_usage: '' // num
        },
        truck_kolodka_state: {
          start_usage: '', // num
          end_usage: '' // num
        }
      },
      num: '4'
    })
  },
  methods: {
    ...mapMutations(['FILTER_DIALOG_TOGGLE', 'SET_FILTER'])
  }
}
</script>

<style scoped>
.reports-toolbar {
  /* border: 1px solid #01534f; */
  height: 100%;
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
}

.reports-toolbar > .el-button {
  padding: 0px !important;
  padding: 0 0.5rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  border: 1px solid transparent;
}

.reports-toolbar > i,
.el-icon-search::before {
  color: white;
}
</style>
