<template>
  <div class="reports-toolbar">
    <toExcel />
    <el-button
      style="
        min-width: 5rem;
        height: 2.5rem;
        background-color: #ffa800;
        color: white;
      "
      icon="el-icon-search"
      @click="FILTER_DIALOG_TOGGLE(true)"
      >Filter</el-button
    >
    <FilterDialog />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import FilterDialog from './filters'
import toExcel from './toExcel'
export default {
  components: {
    FilterDialog,
    toExcel
  },
  beforeDestroy() {
    this.SET_FILTER({
      filter: {
        tashkilot_nomi: '',
        shartnoma: '',
        boshlangich: '',
        hisob_raqami: '',
        mfo: '',
        inn: '',
        oked: '',
        address: '',
        kod_nds: ''
      },
      num: ''
    })
  },
  methods: {
    ...mapMutations(['FILTER_DIALOG_TOGGLE', 'SET_FILTER'])
  }
}
</script>

<style>
.reports-toolbar {
  /* border: 1px solid #01534f; */
  height: 100%;
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
}

.reports-toolbar > .el-button {
  padding: 0px !important;
  padding: 0 0.5rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  border: 1px solid transparent;
}

.reports-toolbar > i,
.el-icon-search::before {
  color: white;
}
</style>
