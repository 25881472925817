<template>
  <el-tooltip
    class="item"
    effect="dark"
    content="'Excel' га чиқариш"
    placement="top-start"
  >
    <el-button
      class="t-btn"
      style="
        min-width: 5rem;
        height: 2.5rem;
        background-color: #01a39b;
        color: white;
      "
      :loading="downloadLoading"
      @click="handleDownload"
    >
      <!-- <svg-icon style="fill: white" icon-class="excel" /> -->
      Excel
    </el-button>
  </el-tooltip>
</template>

<script>
import { parseTime } from '@/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'DHJExcel',
  data() {
    return {
      downloadLoading: false
    }
  },
  created() {},
  computed: {
    ...mapGetters(['filteredData'])
  },
  methods: {
    handleDownload() {
      console.log('filtered data: ', this.filteredData)
      let dtArr = []
      for (let i = 0; i < this.filteredData.filtered.length; i++) {
        let dt = {
          contract_number: this.filteredData.filtered[i].contract_number,
          contract_start_date:
            this.filteredData.filtered[i].contract_start_date,
          ...this.filteredData.filtered[i].client
        }
        dtArr.push(dt)
      }

      this.downloadLoading = true
      import('@/vendor/Export2Excel').then((excel) => {
        const filename = `Filtered(${this.$route.params.id})`
        const multiHeader = [['', '', '', '', '', '', '', '', '']]
        const header = [
          'Tashkilot',
          'Shartnoma',
          'Boshlanish',
          'Hisob raqami',
          'MFO',
          'INN',
          'OKED',
          'Adres',
          'NKod NDSarx'
        ]
        const filterVal = [
          'full_name',
          'contract_number',
          'contract_start_date',
          'bank_account1',
          'bank_code1',
          'inn',
          'oked',
          'yur_address',
          'code_nds'
        ]
        // const list = dtArr
        // console.log('excel data: ', dtArr)
        const data = this.formatJson(filterVal, dtArr)
        const merges = [
          'A2:A2',
          'B2:B2',
          'C2:C2',
          'D2:D2',
          'E2:E2',
          'F2:F2',
          'G2:G2',
          'H2:H2',
          'I2:I2'
        ]
        excel.export_json_to_excel({
          multiHeader,
          header,
          merges,
          data,
          filename
        })
        this.downloadLoading = false
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === 'timestamp') {
            return parseTime(v[j])
          } else {
            return v[j]
          }
        })
      )
    }
  }
}
</script>
