export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

function isObject(item) {
  return typeof item === 'object' && !Array.isArray(item) && item !== null
}

function takeFilledKeyValue(arg) {
  if (!isObject(arg)) return {}

  return Object.keys(arg)
    .filter((key) => arg[key])
    .reduce(
      (r, s) => ({
        ...r,
        [s && s]: arg[s]
      }),
      {}
    )
}

function isNumberKey(evt) {
  let charCode = evt.charCode || evt.which || event.keyCode

  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault()
    return false
  }

  return true
}

function takeNumbersWithRegex(str) {
  return str && (str.match(/\d+$/) || []).join('')
}

/**
 * Dynamically sets a deeply nested value in an object.
 * Optionally "bores" a path to it if its undefined.
 * @function
 * @param {!object} obj  - The object which contains the value you want to change/set.
 * @param {!array} path  - The array representation of path to the value you want to change/set.
 * @param {!mixed} value - The value you want to set it to.
 * @param {boolean} setrecursively - If true, will set value of non-existing path as well.
 */
function setValue(propertyPath, value, obj) {
  // this is a super simple parsing, you will want to make this more complex to handle correctly any path
  // it will split by the dots at first and then simply pass along the array (on next iterations)
  let properties = Array.isArray(propertyPath)
    ? propertyPath
    : propertyPath.split('.')

  // Not yet at the last property so keep digging
  if (properties.length > 1) {
    // The property doesn't exists OR is not an object (and so we overwritte it) so we create it
    if (
      !obj.hasOwnProperty(properties[0]) ||
      typeof obj[properties[0]] !== 'object'
    )
      obj[properties[0]] = {}
    // We iterate.
    return setValue(properties.slice(1), value, obj[properties[0]])
    // This is the last property - the one where to set the value
  } else {
    // We set the value to the last property
    obj[properties[0]] = value
    return true // this is the end
  }
}

function numberWithSpaces(x) {
  if (!x) return ''
  let parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export function toFloat(val) {
  if (typeof val == 'string' && Boolean(val))
    return parseInt(val.replace(/[ ,]/g, ''), 10)
  else if (typeof val == 'number') return val
  else return 0
}

export function toRoundFloat(val) {
  return (
    val &&
    numberWithSpaces(parseInt((val.toString() || '').replace(/[ ,]/g, '')))
  )
}

function currencyOptions() {
  return {
    locale: 'en-US',
    currency: [null],
    valueAsInteger: false,
    distractionFree: true,
    precision: 0,
    autoDecimalMode: true,
    valueRange: { min: 0 },
    allowNegative: false
  }
}

function isValidTime(timeString) {
  return /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(timeString)
}

function objectToUrlParams(obj) {
  let str = ''
  for (let key in obj) {
    if (str != '') {
      str += '&'
    }
    str += key + '=' + encodeURIComponent(obj[key])
  }

  return str
}

export {
  takeFilledKeyValue,
  isObject,
  isNumberKey,
  takeNumbersWithRegex,
  setValue,
  numberWithSpaces,
  currencyOptions,
  isValidTime,
  objectToUrlParams
}
