<template>
  <el-dialog
    title="Filter"
    :visible.sync="reportsStore.filterDialog"
    width="35%"
    top="30px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    align="center"
  >
    <el-row :gutter="5" class="filter-body">
      <el-col :span="24">
        <el-form :model="filter">
          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="gos_number" label="Gos. nomer">
                <el-input
                  clearable
                  v-model="filter.gos_number"
                  placeholder="Gos. nomer"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="dvigatel_no" label="Dvigatel raqami ">
                <el-input
                  clearable
                  v-model="filter.dvigatel_no"
                  placeholder="Dvigatel raqami"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="year" label="Yil">
                <el-input
                  clearable
                  v-model="filter.year"
                  placeholder="2021-01-01"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="bought_date" label="Olingan sana">
                <el-input
                  clearable
                  v-model="filter.bought_date"
                  placeholder="Olingan sana"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="price" label="Narx">
                <el-input
                  clearable
                  v-model="filter.price"
                  placeholder="Narx"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="capacity_kg" label="Yuk ko'tarish og'irligi">
                <el-input
                  clearable
                  v-model="filter.capacity_kg"
                  placeholder="Yuk ko'tarish og'irligi"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="capacity_m3" label="M3">
                <el-input
                  clearable
                  v-model="filter.capacity_m3"
                  placeholder="M3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="odometr" label="Odometr">
                <el-input
                  clearable
                  v-model="filter.odometr"
                  placeholder="Odometr"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item
                prop="odometr_updated_date_manual"
                label="Odometr yangilangan sana"
              >
                <el-input
                  clearable
                  v-model="filter.odometr_updated_date_manual"
                  placeholder="Odometr yangilangan sana"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="kuzov_number" label="Kuzov nomeri">
                <el-input
                  clearable
                  v-model="filter.kuzov_number"
                  placeholder="Kuzov"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="marka" label="Marka">
                <el-input
                  clearable
                  v-model="filter.marka"
                  placeholder="Marka"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="clearAndClose()">Tozalash</el-button>
      <el-button type="primary" style="color: white" @click="search()"
        >Izlash</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  data() {
    return {
      filter: {
        gos_number: '',
        year: '',
        dvigatel_no: '',
        bought_date: '',
        price: '',
        capacity_kg: '',
        capacity_m3: '',
        odometr: '',
        odometr_updated_date_manual: '',
        kuzov_number: '',
        marka: ''
      }
    }
  },
  computed: {
    ...mapState(['reportsStore'])
  },
  methods: {
    ...mapMutations(['FILTER_DIALOG_TOGGLE', 'SET_FILTER', 'SET_QUERY_PARAM']),
    search() {
      this.SET_FILTER({ filter: this.filter, num: '3' })
      this.FILTER_DIALOG_TOGGLE(false)
      this.SET_QUERY_PARAM({ name: 'currentPage', value: 1 })
    },
    clearAndClose() {
      this.FILTER_DIALOG_TOGGLE(false)
      this.filter = {
        gos_number: '',
        year: '',
        dvigatel_no: '',
        bought_date: '',
        price: '',
        capacity_kg: '',
        capacity_m3: '',
        odometr: '',
        odometr_updated_date_manual: '',
        kuzov_number: '',
        marka: ''
      }
      this.SET_FILTER({ filter: this.filter, num: '3' })
    }
  }
}
</script>

<style>
/* .filter-body { */
/* border: 1px solid red; */
/* } */

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
