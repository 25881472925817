export default {
  computed: {
    $_page() {
      return +this.$route.query.page || 1
    },

    $_perPage() {
      return +this.$route.query.limit || 10
    },

    $_pageOffset() {
      return this.$_page * this.$_perPage - this.$_perPage
    }
  }
}
