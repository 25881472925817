<template>
  <el-table
    :data="filteredReport3.finalResult"
    border
    class="h-100"
    size="small"
    v-loading="reportsStore.table_loading"
  >
    <el-table-column align="center" type="index" />

    <el-table-column align="center" width="250" prop="marka" label="Marka" />

    <el-table-column
      align="center"
      width="130"
      prop="get_truck_number"
      label="Gos No"
    />

    <el-table-column align="center" width="100" prop="year" label="Yil" />

    <el-table-column
      align="center"
      width="100"
      prop="dvigatel_number"
      label="Dvigatel No"
    />

    <el-table-column
      align="center"
      width="130"
      prop="kuzov_number"
      label="Kuzov"
    />

    <el-table-column align="center" width="100" prop="" label="Shasse" />

    <el-table-column
      align="center"
      width="150"
      prop="bought_date"
      label="Olingan sana"
    >
      <template slot-scope="scope">
        {{ $dayjs(scope.row.bought_date).format('DD-MM-YYYY') }}
      </template>
    </el-table-column>

    <el-table-column align="center" width="200" prop="" label="Tex passport" />

    <el-table-column
      align="center"
      width="150"
      prop="bought_price"
      label="Narx"
    >
      <template slot-scope="scope">
        {{ scope.row.bought_price | numberWithSpaces }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="150"
      prop="capacity_kg"
      label="Gr podyomnost tonna"
    >
    </el-table-column>
    <el-table-column align="center" width="100" prop="capacity_m3" label="M3">
    </el-table-column>
    <el-table-column align="center" width="200" prop="odometr" label="Odometr">
    </el-table-column>
    <el-table-column
      align="center"
      width="200"
      prop="odometr_updated_date_manual"
      label="Odometr yangilangan sana"
    >
    </el-table-column>
  </el-table>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { numberWithSpaces } from '@/utils'

export default {
  computed: {
    ...mapState(['reportsStore']),
    ...mapGetters(['filteredReport3'])
  },
  mounted() {
    this.getReports(this.$route.params.id).then({})
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Avtomashinalar ro'yxati" }
      ])
    })
    // console.log('Table 3 mounted')
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  },

  filters: {
    numberWithSpaces
  },

  methods: {
    ...mapActions(['getReports'])
  }
}
</script>

<style></style>
