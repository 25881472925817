<template>
  <el-table
    :data="filteredReport4.finalResult"
    border
    class="h-100"
    size="small"
    v-loading="reportsStore.table_loading"
  >
    <el-table-column align="center" width="250" prop="marka" label="marka">
    </el-table-column>
    <el-table-column
      align="center"
      width="125"
      prop="get_truck_number"
      label="mashina raqami"
    >
    </el-table-column>
    <el-table-column align="center" width="125" prop="odometr" label="odometr">
    </el-table-column>
    <el-table-column align="center" width="100" label="Sugurta">
      <el-table-column
        width="120"
        align="center"
        label="sanadan"
        prop="insurance.start_date"
      >
        <template slot-scope="scope">
          {{
            scope.row.insurance &&
            scope.row.insurance.start_date &&
            $dayjs(scope.row.insurance.start_date).format('DD-MM-YYYY')
          }}
        </template>
      </el-table-column>
      <el-table-column
        width="120"
        align="center"
        label="sanagacha"
        prop="insurance.end_date"
      >
        <template slot-scope="scope">
          {{
            scope.row.insurance &&
            scope.row.insurance.end_date &&
            $dayjs(scope.row.insurance.end_date).format('DD-MM-YYYY')
          }}
        </template>
      </el-table-column>
    </el-table-column>

    <el-table-column align="center" width="100" label="Tex osmotr">
      <el-table-column
        width="120"
        align="center"
        label="sanadan"
        prop="technical_monitoring.start_date"
      >
        <template slot-scope="scope">
          {{
            scope.row.technical_monitoring &&
            scope.row.technical_monitoring.start_date &&
            $dayjs(scope.row.technical_monitoring.start_date).format(
              'DD-MM-YYYY'
            )
          }}
        </template>
      </el-table-column>
      <el-table-column
        width="120"
        align="center"
        label="sanagacha"
        prop="technical_monitoring.end_date"
      >
        <template slot-scope="scope">
          {{
            scope.row.technical_monitoring &&
            scope.row.technical_monitoring.end_date &&
            $dayjs(scope.row.technical_monitoring.end_date).format('DD-MM-YYYY')
          }}
        </template>
      </el-table-column>
    </el-table-column>

    <el-table-column align="center" width="100" label="Reklamaga Ruxsat">
      <el-table-column
        width="120"
        align="center"
        label="sanadan"
        prop="advert_permit.start_date"
      >
        <template slot-scope="scope">
          {{
            scope.row.advert_permit &&
            scope.row.advert_permit.start_date &&
            $dayjs(scope.row.advert_permit.start_date).format('DD-MM-YYYY')
          }}
        </template>
      </el-table-column>
      <el-table-column
        width="120"
        align="center"
        label="sanagacha"
        prop="advert_permit.end_date"
      >
        <template slot-scope="scope">
          {{
            scope.row.advert_permit &&
            scope.row.advert_permit.end_date &&
            $dayjs(scope.row.advert_permit.end_date).format('DD-MM-YYYY')
          }}
        </template>
      </el-table-column>
    </el-table-column>

    <el-table-column align="center" width="100" label="Mashina moyi,km">
      <el-table-column
        width="120"
        align="center"
        label="km`dan"
        prop="truck_oil_state.start_usage"
      >
        <template slot-scope="scope">
          {{
            scope.row.truck_oil_state &&
            scope.row.truck_oil_state.start_usage &&
            $dayjs(scope.row.truck_oil_state.start_usage).format('DD-MM-YYYY')
          }}
        </template>
      </el-table-column>
      <el-table-column
        width="120"
        align="center"
        label="km`gacha"
        prop="truck_oil_state.end_usage"
      >
        <template slot-scope="scope">
          {{
            scope.row.truck_oil_state &&
            scope.row.truck_oil_state.end_usage &&
            $dayjs(scope.row.truck_oil_state.end_usage).format('DD-MM-YYYY')
          }}
        </template>
      </el-table-column>
    </el-table-column>

    <el-table-column align="center" width="100" label="Balon,km">
      <el-table-column
        width="120"
        align="center"
        label="km`dan"
        prop="truck_tire_state.start_usage"
      >
        <template slot-scope="scope">
          {{
            scope.row.truck_tire_state &&
            scope.row.truck_tire_state.start_usage &&
            $dayjs(scope.row.truck_tire_state.start_usage).format('DD-MM-YYYY')
          }}
        </template>
      </el-table-column>
      <el-table-column
        width="120"
        align="center"
        label="km`gacha"
        prop="truck_tire_state.end_usage"
      >
        <template slot-scope="scope">
          {{
            scope.row.truck_tire_state &&
            scope.row.truck_tire_state.end_usage &&
            $dayjs(scope.row.truck_tire_state.end_usage).format('DD-MM-YYYY')
          }}
        </template>
      </el-table-column>
    </el-table-column>

    <el-table-column align="center" width="100" label="Akkumulyator">
      <el-table-column
        width="120"
        align="center"
        label="sanadan"
        prop="truck_accumulator_state.start_date_usage"
      >
        <template slot-scope="scope">
          {{
            scope.row.truck_accumulator_state &&
            scope.row.truck_accumulator_state.start_date_usage &&
            $dayjs(scope.row.truck_accumulator_state.start_date_usage).format(
              'DD-MM-YYYY'
            )
          }}
        </template>
      </el-table-column>
      <el-table-column
        width="120"
        align="center"
        label="sanagacha"
        prop="truck_accumulator_state.end_date_usage"
      >
        <template slot-scope="scope">
          {{
            scope.row.truck_accumulator_state &&
            scope.row.truck_accumulator_state.end_date_usage &&
            $dayjs(scope.row.truck_accumulator_state.end_date_usage).format(
              'DD-MM-YYYY'
            )
          }}
        </template>
      </el-table-column>
    </el-table-column>

    <el-table-column align="center" width="100" label="Kolodka">
      <el-table-column
        width="120"
        align="center"
        label="km`dan"
        prop="truck_kolodka_state.start_usage"
      >
        <template slot-scope="scope">
          {{
            scope.row.truck_kolodka_state &&
            scope.row.truck_kolodka_state.start_usage &&
            $dayjs(scope.row.truck_kolodka_state.start_usage).format(
              'DD-MM-YYYY'
            )
          }}
        </template>
      </el-table-column>
      <el-table-column
        width="120"
        align="center"
        label="km`gacha"
        prop="truck_kolodka_state.end_usage"
      >
        <template slot-scope="scope">
          {{
            scope.row.truck_kolodka_state &&
            scope.row.truck_kolodka_state.end_usage &&
            $dayjs(scope.row.truck_kolodka_state.end_usage).format('DD-MM-YYYY')
          }}
        </template>
      </el-table-column>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  computed: {
    ...mapState(['reportsStore']),
    ...mapGetters(['filteredReport4'])
  },

  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "AvtoTex ma'lumot" }])
    })
    this.getReports(this.$route.params.id)
    // console.log('Table 4 mounted')
  },
  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  },

  methods: {
    ...mapActions(['getReports'])
  }
}
</script>
