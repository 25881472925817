<template>
  <el-row class="reports-body d-flex flex-direction-col h-100">
    <el-col
      class="py-4"
      :span="24"
      style="background-color: white; border: 0px solid green"
    >
      <component
        :is="'toolBar' + $route.params.id"
        @filter:dates="onFilterDates"
        @filter:custom_dates="onFilterCustomDates"
        @filter:form="onFormEmit"
        @excel-download-emit="onExcelEmit"
      />
    </el-col>
    <el-col :span="24" class="flex-1 d-flex flex-direction-col">
      <component
        :is="'reportsTable' + $route.params.id"
        class="d-flex flex-direction-col"
      />
    </el-col>
    <el-col
      :span="24"
      style="height: 4rem; border: 0px solid green"
      v-if="!['30'].includes($route.params.id)"
    >
      <component
        :is="'reportsPagination' + $route.params.id"
        @page="onPageEmit"
        @limit="onLimitEmit"
      />
    </el-col>
  </el-row>
</template>

<script>
import axios from 'axios'

import reportsTable1 from './components/reportsTable'
import reportsTable3 from './components/report3/reportsTable3'
import reportsTable4 from './components/report4/reportsTable4'

import toolBar1 from './components/toolbar'
import toolBar3 from './components/report3/toolbar'
import toolBar4 from './components/report4/toolbar'

import reportsPagination1 from './components/pagination'
import reportsPagination3 from './components/report3/pagination'
import reportsPagination4 from './components/report4/pagination'

import { mapActions } from 'vuex'
import { takeFilledKeyValue } from '@/utils'

import paginationMixin from '@/mixins/pagination.mixin.js'

const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth() + 1

export default {
  components: {
    toolBar1,
    toolBar3,
    toolBar4,
    toolBar5: () => import('./components/report5/toolbar'),
    toolBar7: () => import('./components/report7/toolbar'),
    toolBar18: () => import('./components/report18/toolbar'),
    toolBar19: () => import('./components/report19/toolbar'),
    toolBar20: () => import('./components/report20/toolbar'),
    toolBar21: () => import('./components/report21/toolbar'),
    toolBar22: () => import('./components/report22/toolbar'),
    toolBar23: () => import('./components/report23/toolbar'),
    toolBar25: () => import('./components/report25/toolbar'),
    toolBar26: () => import('./components/report26/toolbar'),
    toolBar27: () => import('./components/report27/toolbar'),
    toolBar28: () => import('./components/report28/toolbar'),
    toolBar29: () => import('./components/report29/toolbar'),
    toolBar30: () => import('./components/report30/toolbar'),
    toolBar31: () => import('./components/report31/toolbar'),
    toolBar33: () => import('./components/report33/toolbar'),

    reportsTable1,
    reportsTable3,
    reportsTable4,
    reportsTable5: () => import('./components/report5/reportsTable5'),
    reportsTable7: () => import('./components/report7/reportsTable7'),
    reportsTable18: () => import('./components/report18/reportsTable18'),
    reportsTable19: () => import('./components/report19/reportsTable19'),
    reportsTable20: () => import('./components/report20/reportsTable20'),
    reportsTable21: () => import('./components/report21/reportsTable21'),
    reportsTable22: () => import('./components/report22/reportsTable22'),
    reportsTable23: () => import('./components/report23/reportsTable23'),
    reportsTable25: () => import('./components/report25/reportsTable25'),
    reportsTable26: () => import('./components/report26/reportsTable26'),
    reportsTable27: () => import('./components/report27/reportsTable27'),
    reportsTable28: () => import('./components/report28/reportsTable28'),
    reportsTable29: () => import('./components/report29/reportsTable29'),
    reportsTable30: () => import('./components/report30/reportsTable30'),
    reportsTable31: () => import('./components/report31/reportsTable31'),
    reportsTable33: () => import('./components/report33/reportsTable33'),

    reportsPagination1,
    reportsPagination3,
    reportsPagination4,
    reportsPagination5: () => import('./components/report5/pagination'),
    reportsPagination7: () => import('./components/report7/pagination'),
    reportsPagination18: () => import('./components/report18/pagination'),
    reportsPagination19: () => import('./components/report19/pagination'),
    reportsPagination20: () => import('./components/report20/pagination'),
    reportsPagination21: () => import('./components/report21/pagination'),
    reportsPagination22: () => import('./components/report22/pagination'),
    reportsPagination23: () => import('./components/report23/pagination'),
    reportsPagination25: () => import('./components/report25/pagination'),
    reportsPagination26: () => import('./components/report26/pagination'),
    reportsPagination27: () => import('./components/report27/pagination'),
    reportsPagination28: () => import('./components/report28/pagination'),
    reportsPagination29: () => import('./components/report29/pagination'),
    reportsPagination30: () => import('./components/report30/pagination'),
    reportsPagination31: () => import('./components/report31/pagination'),
    reportsPagination33: () => import('./components/report33/pagination')
  },

  mixins: [paginationMixin],

  watch: {
    $route: {
      handler: 'fetchData',
      immediate: true
    }
  },

  methods: {
    ...mapActions(['getReportByNumber']),

    onTableKeyChange(arg) {
      console.log('onTableKeyChange', arg)
    },

    setRoute(navigateProps) {
      this.$router.push(navigateProps, () => {})
    },

    onPageEmit(page) {
      // console.log('emit page', page)
      this.setRoute({
        query: {
          ...this.$route.query,
          page
        }
      })
    },

    onLimitEmit(limit) {
      // console.log('emit limit', limit)
      this.setRoute({
        query: {
          ...this.$route.query,
          page: 1,
          limit
        }
      })
    },

    fetchData() {
      try {
        const { id } = this.$route.params

        // except routes if params.id not exists in array
        if (
          ![
            '7',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '25',
            '26',
            '27',
            '28',
            '33'
          ].includes(id)
        )
          return

        const { month, year } = this.$route.query

        let queryParams = {
          reportNumber: id,
          params: {
            limit: this.$_perPage,
            offset: this.$_pageOffset,
            // filter: {},
            ...(this.filterFormProps || {})
          }
        }

        if (year) {
          if (
            [
              '18',
              '20',
              '21',
              '22',
              '23',
              '25',
              '26',
              '27',
              '28',
              '31'
            ].includes(id)
          ) {
            queryParams.params.year = year
          } else {
            queryParams.params.start_date2__year = year
          }
        }

        if (month) {
          if (
            ['18', '21', '22', '23', '25', '26', '27', '28', '31'].includes(id)
          ) {
            queryParams.params.month = month
          } else {
            queryParams.params.start_date2__month = month
          }
        }

        if (['21'].includes(id)) {
          if (!this.$route.query.corp_card_id) {
            // console.log('this.$route.query.corp_card_id is null')
            return
          }

          queryParams.extraUrl = `/${this.$route.query.corp_card_id}/`
          queryParams.params.page = this.$_page

          delete queryParams.params.offset
        }

        // if (['28'].includes(id) && !year && !month) {
        //   return
        // }

        if (['7'].includes(id)) {
          const y = year || (!this.firstLoadDone && currentYear)
          const m = month || (!this.firstLoadDone && currentMonth)
          if (y) {
            queryParams.params.start_date2__year = y
          }

          if (m) {
            queryParams.params.start_date2__month = m
          }
        }

        console.log('queryParams.params', queryParams.params)

        this.getReportByNumber(queryParams)
        this.firstLoadDone = true
      } catch (err) {
        //
        this.$store.commit('setIsLoading', false)
      }
    },

    onFilterDates(filterProps) {
      // console.log('filterProps', filterProps)
      const { limit } = this.$route.query
      this.setRoute({
        query: {
          page: 1, // page 1 ga tushirish kere date filter yangilanganda
          limit,
          ...filterProps
        }
      })
    },

    onFilterCustomDates(arg) {
      this.resetFilterFormProps(arg)
      this.onFilterDates(arg)
      this.fetchData()
    },

    resetFilterFormProps(arg) {
      this.filterFormProps = {
        ...arg
      }
    },

    onFormEmit(arg) {
      this.resetFilterFormProps(arg)
      this.fetchData()
    },

    onExcelEmit(reportNumber) {
      this.$store.commit('setIsLoading', true)

      let req = {
        method: 'post',
        url: `reports/report${reportNumber}/excel/`,
        responseType: 'blob',
        data: {
          ...(this.filterFormProps || {})
        }
      }

      // if report id in a list req method will be get or else post
      if (
        [
          '19',
          '20',
          '21',
          '22',
          '23',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30',
          '31'
        ].includes(this.$route.params.id)
      ) {
        delete req.data
        const routeParmas = takeFilledKeyValue(this.$route.query)

        // eslint-disable-next-line no-unused-vars
        const { page, limit, offset, tab, ...usefullProps } = routeParmas

        req.method = 'get'
        req.params = {
          ...usefullProps,
          ...(this.filterFormProps || {})
        }

        if (this.$route.query.corp_card_id) {
          req.url = `reports/report${reportNumber}/excel/${this.$route.query.corp_card_id}/`
        }

        if (['29'].includes(this.$route.params.id)) {
          req.url = `reports/report${this.$route.params.id}/${
            this.$route.query.tab || 'clients'
          }/excel/`
        }
      }

      // console.log('req', req)

      axios(req)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Report${reportNumber}`)
          document.body.appendChild(link)
          link.click()
          link.outerHTML = null
          this.$store.commit('setIsLoading', false)
        })
        .catch((err) => {
          console.error(err)
          this.$store.commit('setIsLoading', false)
        })
    }
  },

  data() {
    return {
      filterFormProps: {},

      firstLoadDone: false
    }
  }
}
</script>

<style>
.reports-body {
  /* height: calc(100vh - 220px); */
  border: 0px solid red;
  /* width: calc(100vw - 289px); */
  width: 100%;
}
</style>
