<template>
  <el-tooltip
    class="item"
    effect="dark"
    content="'Excel' га чиқариш"
    placement="top-start"
  >
    <el-button
      class="t-btn"
      style="
        min-width: 5rem;
        height: 2.5rem;
        background-color: #01a39b;
        color: white;
      "
      :loading="downloadLoading"
      @click="handleDownload"
    >
      <!-- <svg-icon style="fill: white" icon-class="excel" /> -->
      Excel
    </el-button>
  </el-tooltip>
</template>
<script>
import { parseTime } from '@/utils'
import { mapGetters } from 'vuex'
export default {
  name: 'DHJExcel',
  data() {
    return {
      downloadLoading: false
    }
  },
  created() {},
  computed: {
    ...mapGetters(['filteredReport3'])
  },
  methods: {
    handleDownload() {
      console.log('filtered data: ', this.filteredReport3)
      let dtArr = []
      for (let i = 0; i < this.filteredReport3.filtered.length; i++) {
        let dt = {
          ...this.filteredReport3.filtered[i]
        }
        dtArr.push(dt)
      }

      this.downloadLoading = true
      import('@/vendor/Export2Excel').then((excel) => {
        const filename = `Filtered(${this.$route.params.id})`
        const multiHeader = [
          ['', '', '', '', '', '', '', '', '', '', '', '', '']
        ]
        const header = [
          'Marka',
          'Gos No',
          'Yil',
          'Dvigatel No',
          'Kuzov',
          'Shasse',
          'Olingan sana',
          'Tex passport',
          'Narx',
          'Gr podyomnost tonna',
          'M3',
          'Odometr',
          'Odometr yangilangan sana'
        ]
        const filterVal = [
          'marka',
          'get_truck_number',
          'year',
          'dvigatel_number',
          'kuzov_number',
          '',
          'bought_date',
          '',
          'bought_price',
          'capacity_kg',
          'capacity_m3',
          'odometr',
          'odometr_updated_date_manual'
        ]
        // const list = dtArr
        console.log('excel data: ', dtArr)
        const data = this.formatJson(filterVal, dtArr)
        const merges = [
          'A2:A2',
          'B2:B2',
          'C2:C2',
          'D2:D2',
          'E2:E2',
          'F2:F2',
          'G2:G2',
          'H2:H2',
          'I2:I2',
          'J2:J2',
          'K2:K2',
          'L2:L2',
          'M2:M2'
        ]
        excel.export_json_to_excel({
          multiHeader,
          header,
          merges,
          data,
          filename
        })
        this.downloadLoading = false
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === 'timestamp') {
            return parseTime(v[j])
          } else {
            return v[j]
          }
        })
      )
    }
  }
}
</script>
