<template>
  <el-dialog
    title="Filter"
    :visible.sync="reportsStore.filterDialog"
    width="35%"
    top="30px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    align="center"
  >
    <el-row class="filter-body">
      <el-col :span="24">
        <el-form :model="filter">
          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="tashkilot_nomi" label="tashkilot nomi">
                <el-input
                  clearable
                  v-model="filter.tashkilot_nomi"
                  placeholder="Tashkilot nomi"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="shartnoma" label="shartnoma ">
                <el-input
                  clearable
                  v-model="filter.shartnoma"
                  placeholder="shartnoma"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="boshlangich" label="boshlangich">
                <el-input
                  clearable
                  v-model="filter.boshlangich"
                  placeholder="2019-12-12"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="hisob_raqami" label="hisob_raqami">
                <el-input
                  clearable
                  v-model="filter.hisob_raqami"
                  placeholder="hisob_raqami"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="mfo" label="mfo">
                <el-input
                  clearable
                  v-model="filter.mfo"
                  placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="inn" label="inn">
                <el-input
                  clearable
                  v-model="filter.inn"
                  placeholder="inn"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="oked" label="oked">
                <el-input
                  clearable
                  v-model="filter.oked"
                  placeholder="oked"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="address" label="address">
                <el-input
                  clearable
                  v-model="filter.address"
                  placeholder="address"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="kod_nds" label="kod_nds">
                <el-input
                  clearable
                  v-model="filter.kod_nds"
                  placeholder="kod_nds"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="clearAndClose()">Tozalash</el-button>
      <el-button type="primary" style="color: white" @click="search()"
        >Izlash</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  data() {
    return {
      filter: {
        tashkilot_nomi: '',
        shartnoma: '',
        boshlangich: '',
        hisob_raqami: '',
        mfo: '',
        inn: '',
        oked: '',
        address: '',
        kod_nds: ''
      }
    }
  },
  computed: {
    ...mapState(['reportsStore'])
  },
  methods: {
    ...mapMutations(['FILTER_DIALOG_TOGGLE', 'SET_FILTER', 'SET_QUERY_PARAM']),
    search() {
      this.SET_FILTER({ filter: this.filter, num: '' })
      this.FILTER_DIALOG_TOGGLE(false)
      this.SET_QUERY_PARAM({ name: 'currentPage', value: 1 })
    },
    clearAndClose() {
      this.FILTER_DIALOG_TOGGLE(false)
      this.filter = {
        tashkilot_nomi: '',
        shartnoma: '',
        boshlangich: '',
        hisob_raqami: '',
        mfo: '',
        inn: '',
        oked: '',
        address: '',
        kod_nds: ''
      }
      this.SET_FILTER({ filter: this.filter, num: '' })
    }
  }
}
</script>

<style>
.filter-body {
  /* border: 1px solid red; */
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
