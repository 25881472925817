<template>
  <el-dialog
    title="Filter"
    :visible.sync="reportsStore.filterDialog"
    width="35%"
    top="30px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    align="center"
  >
    <el-row :gutter="5" class="filter-body">
      <el-col :span="24">
        <el-form :model="filter">
          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="marka" label="Marka">
                <el-input
                  clearable
                  v-model="filter.marka"
                  placeholder="Marka"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="get_truck_number" label="Mashina nomeri ">
                <el-input
                  clearable
                  v-model="filter.get_truck_number"
                  placeholder="Mashina nomeri"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="insurance" label="Sug'urta sanadan">
                <el-input
                  clearable
                  v-model="filter.insurance.start_date"
                  v-mask="'####-##-##'"
                  placeholder="Sug'urta sanadan"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="insurance" label="Sug'urta sanagacha">
                <el-input
                  clearable
                  v-model="filter.insurance.end_date"
                  v-mask="'####-##-##'"
                  placeholder="Sug'urta sanagacha"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item
                prop="technical_monitoring"
                label="Tex. osmotr sanasidan"
              >
                <el-input
                  clearable
                  v-model="filter.technical_monitoring.start_date"
                  v-mask="'####-##-##'"
                  placeholder="Tex. osmotr sanasidan"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="technical_monitoring"
                label="Tex. osmotr sanasigacha"
              >
                <el-input
                  clearable
                  v-model="filter.technical_monitoring.end_date"
                  v-mask="'####-##-##'"
                  placeholder="Tex. osmotr sanasigacha"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item
                prop="advert_permit"
                label="Reklamaga ruxsat sanadan"
              >
                <el-input
                  clearable
                  v-model="filter.advert_permit.start_date"
                  v-mask="'####-##-##'"
                  placeholder="Reklamaga ruxsat sanadan"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="advert_permit"
                label="Reklamaga ruxsat sanagacha"
              >
                <el-input
                  clearable
                  v-model="filter.advert_permit.end_date"
                  v-mask="'####-##-##'"
                  placeholder="Reklamaga ruxsat sanagacha"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="truck_oil_state" label="Mashina moyi km.dan">
                <el-input
                  clearable
                  v-model="filter.truck_oil_state.start_usage"
                  placeholder="Mashina moyi km.dan"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="truck_oil_state"
                label="Mashina moyi km.gacha"
              >
                <el-input
                  clearable
                  v-model="filter.truck_oil_state.end_usage"
                  placeholder="Mashina moyi km.gacha"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="truck_tire_state" label="Balon km sanadan">
                <el-input
                  clearable
                  v-model="filter.truck_tire_state.start_usage"
                  placeholder="Balon km sanadan"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="truck_tire_state" label="Balon km sanagacha">
                <el-input
                  clearable
                  v-model="filter.truck_tire_state.end_usage"
                  placeholder="Balon km sanagacha"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item
                prop="truck_accumulator_state"
                label="Akkumulyator sanadan"
              >
                <el-input
                  clearable
                  v-model="filter.truck_accumulator_state.start_date_usage"
                  v-mask="'####-##-##'"
                  placeholder="Akkumulyator sanadan"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="truck_accumulator_state"
                label="Akkumulyator sanagacha"
              >
                <el-input
                  clearable
                  v-model="filter.truck_accumulator_state.end_date_usage"
                  v-mask="'####-##-##'"
                  placeholder="Akkumulyator sanagacha"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="truck_kolodka_state" label="Kolodka sanadan">
                <el-input
                  clearable
                  v-model="filter.truck_kolodka_state.start_usage"
                  placeholder="Kolodka sanadan"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="truck_kolodka_state"
                label="Kolodka sanagacha"
              >
                <el-input
                  clearable
                  v-model="filter.truck_kolodka_state.end_usage"
                  placeholder="Kolodka sanagacha"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="clearAndClose()">Tozalash</el-button>
      <el-button type="primary" style="color: white" @click="search()"
        >Izlash</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
const defaultFilterProps = () => ({
  marka: '',
  get_truck_number: '',
  insurance: {
    start_date: '',
    end_date: ''
  },
  technical_monitoring: {
    start_date: '',
    end_date: ''
  },
  advert_permit: {
    start_date: '',
    end_date: ''
  },
  truck_oil_state: {
    start_usage: '', // num
    end_usage: '' // num
  },
  truck_tire_state: {
    start_usage: '', // num
    end_usage: '' // num
  },
  truck_accumulator_state: {
    start_date_usage: '', // num
    end_date_usage: '' // num
  },
  truck_kolodka_state: {
    start_usage: '', // num
    end_usage: '' // num
  }
})
export default {
  data() {
    return {
      filter: {
        ...defaultFilterProps()
      }
    }
  },
  computed: {
    ...mapState(['reportsStore'])
  },
  methods: {
    ...mapMutations(['FILTER_DIALOG_TOGGLE', 'SET_FILTER', 'SET_QUERY_PARAM']),
    search() {
      this.SET_FILTER({
        filter: JSON.parse(JSON.stringify(this.filter)),
        num: '4'
      })
      this.FILTER_DIALOG_TOGGLE(false)
      this.SET_QUERY_PARAM({ name: 'currentPage', value: 1 })
    },
    clearAndClose() {
      this.FILTER_DIALOG_TOGGLE(false)
      this.filter = defaultFilterProps()
      this.SET_FILTER({ filter: this.filter, num: '4' })
    }
  }
}
</script>

<style>
/* .filter-body { */
/* border: 1px solid red; */
/* } */

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
