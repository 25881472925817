<template>
  <el-tooltip
    class="item"
    effect="dark"
    content="'Excel' га чиқариш"
    placement="top-start"
  >
    <el-button
      class="t-btn"
      style="
        min-width: 5rem;
        height: 2.5rem;
        background-color: #01a39b;
        color: white;
      "
      :loading="downloadLoading"
      @click="handleDownload"
    >
      <!-- <svg-icon style="fill: white" icon-class="excel" /> -->
      Excel
    </el-button>
  </el-tooltip>
</template>
<script>
import { parseTime } from '@/utils'
import { mapGetters } from 'vuex'

const headerValues = {
  start_date: 'Boshlash sanasi',
  end_date: 'Tugash sanasi',

  start_usage: 'Foydalanish sanasidan',
  end_usage: 'Foydalanish sanasigacha'
}
export default {
  name: 'DHJExcel',
  data() {
    return {
      downloadLoading: false
    }
  },
  created() {},
  computed: {
    ...mapGetters(['filteredReport4'])
  },
  methods: {
    handleDownload() {
      // console.log('filtered data: ', this.filteredReport4)
      let dtArr = []
      for (let i = 0; i < this.filteredReport4.filtered.length; i++) {
        let dt = {
          marka: this.filteredReport4.filtered[i].marka,
          get_truck_number: this.filteredReport4.filtered[i].get_truck_number,
          insurance_start_date:
            this.filteredReport4.filtered[i].insurance === null
              ? ''
              : this.filteredReport4.filtered[i].insurance.start_date,
          insurance_end_date:
            this.filteredReport4.filtered[i].insurance === null
              ? ''
              : this.filteredReport4.filtered[i].insurance.end_date,
          technical_monitoring_start_date:
            this.filteredReport4.filtered[i].technical_monitoring === null
              ? ''
              : this.filteredReport4.filtered[i].technical_monitoring
                  .start_date,
          technical_monitoring_end_date:
            this.filteredReport4.filtered[i].technical_monitoring === null
              ? ''
              : this.filteredReport4.filtered[i].technical_monitoring.end_date,
          advert_permit_start_date:
            this.filteredReport4.filtered[i].advert_permit === null
              ? ''
              : this.filteredReport4.filtered[i].advert_permit.start_date,
          advert_permit_end_date:
            this.filteredReport4.filtered[i].advert_permit === null
              ? ''
              : this.filteredReport4.filtered[i].advert_permit.end_date,
          truck_oil_state_start_usage:
            this.filteredReport4.filtered[i].truck_oil_state === null
              ? ''
              : this.filteredReport4.filtered[i].truck_oil_state.start_usage,
          truck_oil_state_end_usage:
            this.filteredReport4.filtered[i].truck_oil_state === null
              ? ''
              : this.filteredReport4.filtered[i].truck_oil_state.end_usage,
          truck_tire_state_start_usage:
            this.filteredReport4.filtered[i].truck_tire_state === null
              ? ''
              : this.filteredReport4.filtered[i].truck_tire_state.start_usage,
          truck_tire_state_end_usage:
            this.filteredReport4.filtered[i].truck_tire_state === null
              ? ''
              : this.filteredReport4.filtered[i].truck_tire_state.end_usage,
          truck_accumulator_state_start_usage:
            this.filteredReport4.filtered[i].truck_accumulator_state === null
              ? ''
              : this.filteredReport4.filtered[i].truck_accumulator_state
                  .start_usage,
          truck_accumulator_state_end_usage:
            this.filteredReport4.filtered[i].truck_accumulator_state === null
              ? ''
              : this.filteredReport4.filtered[i].truck_accumulator_state
                  .end_usage,
          truck_kolodka_state_start_usage:
            this.filteredReport4.filtered[i].truck_kolodka_state === null
              ? ''
              : this.filteredReport4.filtered[i].truck_kolodka_state
                  .start_usage,
          truck_kolodka_state_end_usage:
            this.filteredReport4.filtered[i].truck_kolodka_state === null
              ? ''
              : this.filteredReport4.filtered[i].truck_kolodka_state.end_usage
        }
        dtArr.push(dt)
      }

      this.downloadLoading = true
      import('@/vendor/Export2Excel').then((excel) => {
        const filename = `Filtered(${this.$route.params.id})`
        const multiHeader = [
          [
            '',
            '',
            "Sug'urta",
            '',
            'Tex. osmotr',
            '',
            'Reklamaga ruxsat',
            '',
            'Mashina moyi km',
            '',
            'Mashina moyi km',
            '',
            'Mashina baloni km',
            '',
            'Akkumulyator',
            '',
            'Kolodka',
            ''
          ]
        ]
        const header = [
          'Marka',
          'Gos No',

          headerValues.start_date,
          headerValues.end_date,

          headerValues.start_date,
          headerValues.end_date,

          headerValues.start_date,
          headerValues.end_date,

          headerValues.start_usage, // num
          headerValues.end_usage, // num

          headerValues.start_usage, // num
          headerValues.end_usage, // num

          headerValues.start_usage, // num
          headerValues.end_usage, // num

          headerValues.start_usage, // num
          headerValues.end_usage // num
        ]
        const filterVal = [
          'marka',
          'get_truck_number',
          'insurance_start_date',
          'insurance_end_date',
          'technical_monitoring_start_date',
          'technical_monitoring_end_date',
          'advert_permit_start_date',
          'advert_permit_end_date',
          'truck_oil_state_start_usage',
          'truck_oil_state_end_usage',
          'truck_tire_state_start_usage',
          'truck_tire_state_end_usage',
          'truck_accumulator_state_start_usage',
          'truck_accumulator_state_end_usage',
          'truck_kolodka_state_start_usage',
          'truck_kolodka_state_end_usage'
        ]
        // const list = dtArr
        console.log('excel data: ', dtArr)
        const data = this.formatJson(filterVal, dtArr)
        const merges = [
          'A2:A2',
          'B2:B2',
          'C1:D1',
          'E1:F1',
          'G1:H1',
          'I1:J1',
          'K1:L1',
          'M1:N1',
          'O1:P1',
          'Q1:R1'
        ]
        excel.export_json_to_excel({
          multiHeader,
          header,
          merges,
          data,
          filename
        })
        this.downloadLoading = false
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === 'timestamp') {
            return parseTime(v[j])
          } else {
            return v[j]
          }
        })
      )
    }
  }
}
</script>
