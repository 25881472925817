<template>
  <el-table
    :data="filteredData.finalResult"
    border
    height="100%"
    size="small"
    v-loading="reportsStore.table_loading"
  >
    <el-table-column align="center" type="index"> </el-table-column>
    <el-table-column
      align="center"
      width="200"
      prop="client.full_name"
      label="Tashkilot"
    >
    </el-table-column>
    <el-table-column
      align="center"
      width="250"
      prop="contract_number"
      label="Shartnoma"
    >
    </el-table-column>
    <el-table-column
      align="center"
      width="130"
      prop="contract_start_date"
      label="Boshlanish"
    >
      <template slot-scope="scope">
        {{ $dayjs(scope.row.contract_start_date).format('DD-MM-YYYY') }}
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      width="200"
      prop="client.bank_account1"
      label="Hisob raqami"
    >
    </el-table-column>
    <el-table-column
      align="center"
      width="100"
      prop="client.bank_code1"
      label="MFO"
    >
    </el-table-column>
    <el-table-column align="center" width="130" prop="client.inn" label="INN">
    </el-table-column>
    <el-table-column align="center" width="100" prop="client.oked" label="OKED">
    </el-table-column>
    <el-table-column
      align="center"
      width="250"
      prop="client.yur_address"
      label="Adres"
    >
    </el-table-column>
    <el-table-column
      align="center"
      width="200"
      prop="client.code_nds"
      label="Kod NDS"
    >
    </el-table-column>
  </el-table>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  computed: {
    ...mapState(['reportsStore']),
    ...mapGetters(['filteredData'])
  },

  mounted() {
    this.getReports(this.$route.params.id)
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Shartnoma va mijozlar ro'yxati" }
      ])
    })
  },

  methods: {
    ...mapActions(['getReports'])
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  }
}
</script>

<style></style>
